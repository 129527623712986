// Customize Bootstrap variables
@import 'variables';

// Boostrap scss
@import '~bootstrap/scss/bootstrap';

/* React select custom styles to mimic bootstrap's inputs */
.hm-rs__control {
  border: 1px solid $input-border-color !important;
}
.hm-rs__value-container {
  cursor: text !important;
}
.hm-rs__control--is-focused {
  border: 1px solid $input-focus-border-color !important;
  box-shadow: $input-focus-box-shadow !important;
}

// Hack for Pagination
.page-item > .page-link {
  border-radius: 6px !important;
}

// Hack for Breadcrumbs
.breadcrumb {
  align-items: center !important;
  font-weight: 600 !important;
}
// NOTE: Use a fixed height to avoid layout shift when buttons vs no buttons
// when using for example only padding to make da height
.page-header {
  height: 75px !important;
}

.page-footer {
  position: absolute;
  bottom: $spacer;// * 3;
  padding: $spacer / 2;
  background: white;
  width: max-content;
  right: $spacer * 3;
  box-shadow: $box-shadow-sm;
  border-radius: $border-radius;
}

// Theming all admin form
.hm-form {
  width: 100%;
  padding: 0 $spacer * 3;
  margin: auto;
  margin-bottom: 80px;
}